
































































import { Vue, Component, Watch } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import pieChart from '@/components/chart/pieChart.vue'
import { formatRes } from '@/utils/tools'
import { parsePrice } from '@/utils/validate'
import { fetchNowAs, fetchNowChart, fetchSuggestChart } from '@/api/portfolio'
import { childEduPlanSave, childfEduInfo, childfindInfo } from '@/api/child'
import { FivePlan } from '@/types/child'
import lineChart from '@/components/chart/lineChart.vue'
import { CommonMudule } from '@/store/modules/common'
import { cloneDeep } from 'lodash'
import variables from '@/styles/_variables.scss'
import echarts from 'echarts'
import AnalyMixins from '@/utils/mixins/analy-mixins'
import { mixins } from 'vue-class-component'
import { AnalyModule } from '@/store/modules/analy'
import tableTextBar from '@/components/devCom/tableTextBar.vue'

@Component({
  components: {
    moduleHeader,
    pieChart,
    lineChart,
    tableTextBar
  }
})
export default class extends mixins(AnalyMixins) {
    private asObj = {
      customerAssetAllocationTable: [],
      totalAssetAmount: 0,
      suggestAddUsd: 0,
      equivalentCny: 0
    }
   private name=CommonMudule.customerName
   private objModel:FivePlan = {
     addInvestmentAutoUnderFive: false,
     percentage: 0,
     customerId: CommonMudule.customerId,
     id: ''
   }
   totalMoney = 0
   async saveFive() {
     if (this.objModel.addInvestmentAutoUnderFive !== null && this.objModel.percentage) {
       if (this.objModel.id) {
         this.objModel.id = this.objModel.id
       }
       const obj = cloneDeep(this.objModel)
       obj.percentage = Number(this.objModel.percentage)
       const res = await childEduPlanSave(obj)
     }
   }
   private options = [{
     label: '是',
     value: true,
   }, {
     label: '否',
     value: false,
   }]
    isLoSixive = false
    isLowFive = false
    get getChildTeachObjArr() {
      let num = 0
      AnalyModule.childTeachObjArr.map((item:any) => {
        if (item.assetAnalysisItemTable && item.assetAnalysisItemTable.length > 0) {
          item.assetAnalysisItemTable.map((mt:any) => {
            if (mt.id === 'asset-analysis-item-3-2005') {
              num += parsePrice(mt.calFieldValue)
            }
          })
        }
      })
      this.totalMoney = num
      return AnalyModule.childTeachObjArr
    }

    created() {
      this.initFetch()
      this.getChildInfoAction()
    }
    mounted() {
    }
    reset() {
      this.$router.push({
        // name: 'childTeachOne',
        path: '/childTeach/index',
        params: {
          name: 'reset'
        }
      })
    }
    Submit() {
      this.$router.push('/childTeach/child3_others')
    }
    async initFetch() {
      const p = {
        customerId: CommonMudule.customerId
      }
      const resArr = await childfEduInfo(p)
      if (resArr.data.length === 0) {
        this.isLoSixive = false
      } else {
        const st = resArr.data.find((item:any) => { return item.age < 17 && item.age > 0 })
        const five = resArr.data.find((item:any) => { return item.age < 6 && item.age > 0 })
        if (st) {
          this.isLoSixive = true
        } else {
          this.isLoSixive = false
        }
        if (five) {
          this.isLowFive = true
        } else {
          this.isLowFive = false
        }
      }
      if (this.isLoSixive) {
        const res = await childfindInfo({ customerId: CommonMudule.customerId })
        if (res.data) {
          this.objModel.percentage = Number(res.data.percentage)
          this.objModel.addInvestmentAutoUnderFive = res.data.addInvestmentAutoUnderFive
          this.objModel.id = res.data.id
        }
      }
    }
}
