



















import { Vue, Component } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import rTagCom from './rTagCom.vue'
import planGroup from './plan.vue'
import SplitPane from 'vue-splitpane'
import { childfindInfo } from '@/api/child'
import { CommonMudule } from '@/store/modules/common'

@Component({
  name: 'portfolio',
  components: {
    moduleHeader,
    SplitPane,
    planGroup,
    rTagCom
  }
})
export default class extends Vue {
  isPlan = true
  centerDialogVisible = true
  private isFirst:boolean = true
  resize() {
    // Handle resize
  }
}
