import request from '@/utils/request'

/**
 * 获取可选下载单位
 */
export const fetchExportStatus = (params: { customerId?: string }) =>
  request({
    url: '/blackcat/export/status',
    method: 'get',
    params
  })
