






















































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import { childText } from '@/utils/pageBottomText'
import PageButtomText from '@/components/pageBottomText/index.vue'
import pieChart from '@/components/chart/pieChart.vue'
import { SeriesType } from '@/types/kyc'
import lineChart from '@/components/chart/lineChart.vue'
import stackChart from '@/components/chart/stackChart.vue'
import { CommonMudule } from '@/store/modules/common'
import { cloneDeep, isObject } from 'lodash'
import variables from '@/styles/_variables.scss'
import handleBtn from '@/components/handleBtn/index.vue'
import { childrensList, childfInsuranceList, childfInsuranceSave, childfInsurance, childfStatistics, childfindInfo, childTransaction, childfInvestment, childInvestmentSave, childLineChart, childfEduInfo, childEduDelete } from '@/api/child'
import { InsuranceList, prochildList, insureAllocations, saveChild, ChildEduInfo } from '@/types/child'
import { fetchProductList } from '@/api/portfolio'
import { parsePrice, comdify } from '@/utils/validate'
import { getFinancialAnalysisInfo } from '@/api/articles'
// import { keepTwoDecimalFull, rmbFilter, comdifyKill } from '@/utils/filter'

@Component({
  name: 'notBlockOne',
  components: {
    moduleHeader,
    pieChart,
    lineChart,
    stackChart,
    PageButtomText,
    handleBtn
  }
})
export default class extends Vue {
    childText =childText
    private asObj = {
      customerAssetAllocationTable: [],
      totalAssetAmount: 0,
      suggestAddUsd: 0,
      equivalentCny: 0
    }
    inAmount = 0
    options= []
    childOptions=[]
    value= []
    loading = false
    isInvest = false
    private searInsureObj:InsuranceList = {
      orderField: 'ranking',
      containsMarriageFunds: true,
      name: '',
      insuranceType: 'ChildrenEdu'
    }
     private planObj = {
       productName: '',
       targetAmount: 0,
       investPlanAutoFreq: 'Monthly',
       investAmount: '',
       incomePercentage: 0,
       allTotal: 0,
       productInfoLink: ''
     }
   private insureAllocationTable = []
   searInsureObjList: {
     [key: string]: any
   } = {}
   getStackOption(data1: any, data2: any, data3: any, age: any) {
     //  let max = 0
     //  const min = 0
     //  data2.forEach((item: number, idx: number) => {
     //    const bonus = data3[idx] ? data3[idx] : 0
     //    if (item > this.accAdd(max, bonus)) {
     //      max = this.accAdd(max, bonus)
     //    }
     //  })
     //  data1.forEach((item: number) => {
     //    if (item > max) {
     //      max = item
     //    }
     //    if (item < min) {
     //      min = item
     //    }
     //  })
     const len = age.length
     let wid = 20
     if (len) {
       wid = Math.floor(this.accMul(700, len))
       wid = wid > 10 ? wid - 6 : 4
     }
     const otption = {
       color: ['#6095fd ', '#f15f50', '#a2caa5'],
       tooltip: {
         trigger: 'axis',
         axisPointer: { // 坐标轴指示器，坐标轴触发有效
           type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
         }
       },
       legend: {
         data: ['年保费', '固定年金领取', '中等红利'],
       },
       xAxis: [{
         axisLabel: {
           interval: 10,
           onZero: false
         },
         data: age
       }],
       yAxis: {
         //  max: max,
         //  min: min,
         scale: true,
         axisLine: {
           onZero: false,
           show: false,
           interval: 0
         },
         stackLabels: { // 堆叠数据标签
           enabled: true,
           style: {
             fontWeight: 'bold',
             color: 'gray'
           }
         }
       },
       series: [{
         name: '年保费',
         type: 'bar',
         stack: 'vistors',
         barWidth: wid,
         data: data1
       },
       {
         name: '固定年金领取',
         type: 'bar',
         stack: 'vistors',
         barWidth: wid,
         data: data2
       }, {
         name: '中等红利',
         type: 'bar',
         stack: 'vistors',
         barWidth: wid,
         data: data3
       }]
     }
     return otption
   }
   getLineOption(data1:any, age:any) {
     if (data1.length === 0) {
       data1 = [0]
     }
     const barOption = {
       color: [variables.chartColor],
       legend: {
         left: 'center',
         data: []
       },
       grid: {
         left: '10%',
         right: '2%',
         bottom: '3%',
         containLabel: true
       },
       xAxis: [
         {
           type: 'category',
           axisTick: { show: false, alignWithLabel: true },
           axisLabel: { // 重点在这一块，其余可以忽略
             interval: 10, // 这个一定要有，别忘记了
             textStyle: {
               color: '#333',
               fontSize: 12
             }
           },
           axisLine: {
             onZero: false,
             show: true,
             interval: 0,
             lineStyle: {
               color: '#333'
             }
           },
           data: age
         }
       ],
       yAxis: {
         axisLine: { show: false },
         axisTick: { show: false },
         type: 'value',
         name: '产品长期利益演示图',
         splitLine: {
           show: true,
           lineStyle: {
             type: 'dashed'
           }
         }
       },
       tooltip: {
       },
       series: [{
         type: 'line',
         name: '',
         symbol: 'circle', // 折线点设置为实心点
         symbolSize: 10, // 折线点的大小
         itemStyle: {
           normal: {
             color: [variables.chartColor], // 折线点的颜色
             lineStyle: {
               color: [variables.chartColor]// 折线的颜色
             }
           }
         },
         data: data1
       }]
     }
     return barOption
   }
   pageChild = []
   noStatis = false
    private seriesNow: SeriesType = {
      type: 'pie',
      hoverAnimation: true,
      data: []
    }
    accMul(arg1:any, arg2:any) { // 除法
      let t1 = 0
      let t2 = 0
      try {
        t1 = arg1.toString().split('.')[1].length
      } catch (e) {
      // s
      }
      try {
        t2 = arg2.toString().split('.')[1].length
      } catch (e) {
      // s
      }
      const r1 = Number(arg1.toString().replace('.', ''))
      const r2 = Number(arg2.toString().replace('.', ''))
      return (r1 / r2) * Math.pow(10, t2 - t1)
    }
    accAdd(arg1:any, arg2:any) { // 加法
      let r1
      let r2
      try {
        r1 = arg1.toString().split('.')[1].length
      } catch (e) {
        r1 = 0
      }
      try {
        r2 = arg2.toString().split('.')[1].length
      } catch (e) {
        r2 = 0
      }
      const m = Math.pow(10, Math.max(r1, r2))
      return (arg1 * m + arg2 * m) / m
    }
    created() {
      this.getInit()
      this.getPageInfo()
      this.childfInsuranceList('')
    }
    async getInit() {
      const res = await getFinancialAnalysisInfo({ customerId: CommonMudule.customerId })
      if (res.success) {
        this.inAmount = res.data.customerMember.inAmount
      }
    }
    async reset() {
      const data = await childEduDelete({ customerId: CommonMudule.customerId })
      if (data.success) {
        this.$router.push('/childTeach/child3')
      }
    }
    async getRemoteMethod(val:any) {
      const param = {
        page: 0,
        size: 20,
        enableAutomaticInvest: true,
        productName: ''
      }
      if (val && !isObject(val)) {
        param.productName = val
      }
      const res = await fetchProductList(param)
      this.options = res.data.content
    }
    async remoteMethod(query:any) {
      this.getRemoteMethod(query)
    }
    getTimes(val: string) {
      let res
      if (val === 'Annual') {
        res = 12
      } else if (val === 'Quarterly') {
        res = 3
      } else if (val === 'Monthly') {
        res = 1
      } else {
        res = 0
      }
      return res
    }
    getYears(val: string) {
      let res
      if (val === 'Annual') {
        res = 1
      } else if (val === 'Quarterly') {
        res = 4
      } else if (val === 'Monthly') {
        res = 12
      } else {
        res = 0
      }
      return res
    }
    async investMoney(item:any, index:any) {
      let proInfo = (this as any).pageChild[index].planObj
      if (!isObject(proInfo.productName)) {
        proInfo = {
          productId: proInfo.productId,
          productName: proInfo.productName,
          productExpectedReturn: proInfo.productExpectedReturn,
          id: proInfo.productId
        }
      } else {
        proInfo = {
          id: proInfo.productName.id,
          productName: proInfo.productName.productName,
          productExpectedReturn: proInfo.productName.productExpectedReturn
        }
      }
      if (item.planObj.investPlanAutoFreq && item.planObj.investAmount) {
        (this as any).pageChild[index].planObj.incomePercentage = Number(item.planObj.investAmount * this.getTimes(item.planObj.investPlanAutoFreq) / this.inAmount) * 100
      }
      if (item.planObj.investPlanAutoFreq && item.planObj.investAmount && proInfo.id && proInfo.productName) {
        (this as any).pageChild[index].planObj.productExpectedReturn = proInfo.productExpectedReturn
        // 每期定投资金*((1+r')^ n' -1)/r'
        const t = this.getTimes(item.planObj.investPlanAutoFreq)
        const r = (parsePrice(proInfo.productExpectedReturn) / 1200) * t
        const tt = this.getYears(item.planObj.investPlanAutoFreq)
        const n = (18 - item.age) * tt
        const p = {
          incomePercentage: (this as any).pageChild[index].planObj.incomePercentage,
          customerId: CommonMudule.customerId,
          customerMemberId: item.id,
          investAmount: item.planObj.investAmount,
          investPlanAutoFreq: item.planObj.investPlanAutoFreq,
          insuranceType: 'ChildrenEdu',
          productId: proInfo.id,
          productName: proInfo.productName,
          productExpectedReturn: proInfo.productExpectedReturn,
          productInfoLink: proInfo.productInfoLink,
          id: item.planObj.id
        }
        const res = await childInvestmentSave(p)
        if (res.success) {
          this.childLineChart(item.id)
          const appArr = await this.childLineChart(item.id);
          (this as any).pageChild[index].investOption = this.getLineOption(appArr.data2, appArr.data1);
          (this as any).pageChild[index].planObj = await this.childfInvestment(item.id);
          (this as any).pageChild[index].planObj.allTotal = (parsePrice(item.planObj.investAmount) * (Math.pow((1 + r), n) - 1)) / r
        } else {
          this.$message({
            message: res.errorMsg,
            type: 'error'
          })
        }
        this.$forceUpdate()
      }
    }
    productInfoLink(val:string) {
      window.open(val)
    }
    async childClickList(id: string, val:string) {
      if (val === 'ranking' || val === 'cashValue') {
        this.searInsureObjList[id].orderField = val
      }
      const data = cloneDeep(this.searInsureObjList[id])
      if (data.containsMarriageFunds) {
        delete data.containsMarriageFunds
      }
      const resArr = await childfInsuranceList(data)
      this.childOptions = resArr.data
    }
    async childfInsuranceList(val:string) {
      this.searInsureObj.name = val
      const data = cloneDeep(this.searInsureObj)
      if (data.containsMarriageFunds) {
        delete data.containsMarriageFunds
      }
      const resArr = await childfInsuranceList(data)
      this.childOptions = resArr.data
    }
    async childfInsurance(id:any) {
      const p = {
        customerId: CommonMudule.customerId,
        childId: id
      }
      const dataItem = {}
      const r1:any = []
      const r2:any = []
      const r3:any = []
      const r4:any = []
      const universalAccountValue:any = []

      const res = await childfInsurance(p)
      const dataAll = res.data
      if (res.data && res.data.length > 0) {
        res.data.map((nn:any) => {
          r1.push(-nn.yearMny)
          r2.push(nn.fixMny)
          r3.push(nn.bonus)
          r4.push(nn.age)
          universalAccountValue.push(nn.universalAccountValue)
        })
      }
      return { r1, r2, r3, r4, universalAccountValue, dataAll }
    }
    async childfStatistics(id:any) {
      const p = {
        customerId: CommonMudule.customerId,
        childId: id
      }
      const res = await childfStatistics(p)
      let temp
      if (res.data) {
        const t1 = (res.data.totalPremium).toString()
        const t2 = (res.data.totalAnnuityDraw).toString()
        const t3 = (res.data.totalAmountDraw).toString()
        res.data.totalPremium = comdify(t1)
        res.data.totalAnnuityDraw = comdify(t2)
        res.data.totalAmountDraw = comdify(t3)
        temp = res.data
      } else {
        temp = {
          annuityMultiple: '', // 期末生存总利益/总保费
          totalAmountDraw: '', // 生存总利益
          totalAnnuityDraw: '', // 累计保证年金领取
          totalBonusDraw: '', // 累计红利领取
          totalPremium: '', // 总保费
          noStatis: false
        }
        if (res.statusCode === 2002) {
          temp.noStatis = true
        }
      }
      return temp
    }
    clearVal(val:any) {
      if (!val) {
        this.childOptions = []
      }
    }
    async submitMoney(item:any, outIndex:any) {
      const test = JSON.stringify(item.planMoney.insurance) === '{}'
      if (isObject(item.planMoney.insurance) && !test) {
        (this as any).pageChild[outIndex].planMoney.yearOptions = this.focusChildfInsuranceList(item, outIndex)
      }
      if (item.planMoney.totalAmount && item.planMoney.years && item.planMoney.insurance) {
        (this as any).pageChild[outIndex].planMoney.yearlyAmount = Number(Number(item.planMoney.totalAmount) / item.planMoney.years);
        (this as any).pageChild[outIndex].planMoney.incomePercentage = (Number(item.planMoney.totalAmount) / this.inAmount) * 100
        this.$forceUpdate()
        this.saveTotal(item.planMoney, item, outIndex)
      }
    }
    focusChildfInsuranceList(item:any, index:any) {
      const test = JSON.stringify(item.planMoney.insurance) === '{}'
      if (isObject(item.planMoney.insurance) && !test) {
        const yearArr = item.planMoney.insurance.paymentTerms.split(',')
        const arr:any = []
        yearArr.forEach((item:any) => {
          if (item === '1') {
            arr.push({
              label: '趸交',
              value: Number(item)
            })
          } else {
            arr.push({
              label: item + '年',
              value: Number(item)
            })
          }
        })
        return arr
      }
    }
    async saveTotal(val:any, item: any, outIndex:any) {
      // const childId = (this as any).pageChild[outIndex].id]
      const tt = cloneDeep(this.searInsureObj)
      const param:saveChild = {
        years: val.years,
        totalAmount: val.totalAmount,
        customerId: CommonMudule.customerId,
        customerMemberId: item.id,
        yearlyAmount: val.yearlyAmount,
        insuranceType: val.insurance.insuranceType,
        insuranceId: val.insurance.id,
        incomePercentage: val.incomePercentage
      }
      if (val.id) {
        param.id = val.id
      }
      if (Object.prototype.toString.call(tt.name) === '[object Object]') {
        if (tt.name) {
          param.insuranceType = tt.name.insuranceType
          param.insuranceId = tt.name.id
        }
      }
      const res = await childfInsuranceSave(param)
      if (res.success) {
        const st = await this.childfStatistics(item.id)
        const ins = await this.childfInsurance(item.id);
        (this as any).pageChild[outIndex].planMoney = await this.childTransaction(item.id);
        (this as any).pageChild[outIndex].insureAllocation = st;
        (this as any).pageChild[outIndex].stackoption = this.getStackOption(ins.r1, ins.r2, ins.r3, ins.r4);
        (this as any).pageChild[outIndex].barOption = this.getLineOption(ins.universalAccountValue, ins.r4);
        (this as any).pageChild[outIndex].insureAllocationTable = ins.dataAll;
        (this as any).pageChild[outIndex].planMoney.yearOptions = this.focusChildfInsuranceList(item, outIndex)
        if (st.statusCode === 2002) {
          this.noStatis = true
        } else {
          this.noStatis = false
        }
      }
    }
    async initFetch(id:any) {
      const p = {
        customerId: CommonMudule.customerId,
        childId: id
      }
      const resArr = await childrensList(p)
      return resArr.data
    }
    async childfInvestment(id:any) {
      const p = {
        customerId: CommonMudule.customerId,
        childId: id
      }
      const resArr = await childfInvestment(p)
      if (!resArr.success) {
        resArr.data = this.planObj
      }
      resArr.data.allTotal = 0
      return resArr.data
    }
    async childLineChart(id:any) {
      const p = {
        customerId: CommonMudule.customerId,
        childId: id
      }
      const resArr = await childLineChart(p)
      const data1:any = []
      const data2:any = []
      if (resArr.data && resArr.data.length > 0) {
        resArr.data.map((item:any) => {
          data1.push(item.term)
          data2.push(item.amount)
        })
      }

      return { data1, data2 }
    }
    async getPageInfo() {
      const p = {
        customerId: CommonMudule.customerId,
      }
      const res0 = await childfindInfo(p)
      if (res0.data) {
        this.isInvest = true
      } else {
        this.isInvest = false
      }
      const res = await childfEduInfo(p)
      if (!res.data || res.data.length === 0) {
        this.reset()
        return false
      }

      if (res.data && res.data.length > 0) {
        res.data.map(async(item:any, index:any) => {
          // 超过16岁，不请求
          if (item.age <= 16) {
            Vue.set(item, 'childMoney', await this.initFetch(item.id))
          }

          const planItem = await this.childTransaction(item.id)
          const obj = {
            planMoney: planItem
          }
          planItem.yearOptions = this.focusChildfInsuranceList(obj, index)
          Vue.set(item, 'planMoney', planItem)
          const statis = await this.childfStatistics(item.id)
          Vue.set(item, 'insureAllocation', statis)
          const ins = await this.childfInsurance(item.id)
          Vue.set(item, 'insureAllocationTable', ins.dataAll)
          Vue.set(item, 'stackoption', this.getStackOption(ins.r1, ins.r2, ins.r3, ins.r4))
          Vue.set(item, 'barOption', this.getLineOption(ins.universalAccountValue, ins.r4))
          if (item.age < 6 && this.isInvest) { // 定投
            Vue.set(item, 'planObj', await this.childfInvestment(item.id))
            // 每期定投资金*((1+r')^ n' -1)/r'
            const t = this.getTimes(item.planObj.investPlanAutoFreq)
            const r = (parsePrice(item.planObj.productExpectedReturn) / 1200) * t
            const tt = this.getYears(item.planObj.investPlanAutoFreq)
            const n = (18 - item.age) * tt
            item.planObj.allTotal = parsePrice(item.planObj.investAmount) * (Math.pow((1 + r), n) - 1) / r
            const appArr = await this.childLineChart(item.id)
            Vue.set(item, 'investOption', this.getLineOption(appArr.data2, appArr.data1))
          }
        })
        const searInsureObjList:any = {}
        const childs = res.data.filter((item: any) => {
          searInsureObjList[item.id] = {
            orderField: 'ranking',
            containsMarriageFunds: true,
            name: '',
            insuranceType: 'ChildrenEdu'
          }
          return item.age < 17
        })
        this.searInsureObjList = searInsureObjList
        this.pageChild = childs
        this.$forceUpdate()
      }
    }
    async childTransaction(id:any) {
      const p = {
        customerId: CommonMudule.customerId,
        childId: id
      }
      const resArr = await childTransaction(p)
      let res
      if (resArr.data) {
        resArr.data.universalAccount = resArr.data.insurance.universalAccount
        const re = (resArr.data.yearlyAmount).toString()
        resArr.data.yearlyAmount = comdify(re)
        res = resArr.data
      } else {
        res = {
          totalAmount: '',
          years: '',
          customerId: '',
          insuranceType: '',
          customerMemberId: '',
          insuranceId: '',
          yearlyAmount: '',
          insurance: {},
          incomePercentage: '',
          id: '',
          universalAccount: false,
          yearOptions: []
        }
      }
      return res
    }
    handleWindowOpen(url:any) {
      window.open(url)
    }
}
