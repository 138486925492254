import { OptArr } from '@/types/kyc'

export const familyStatus:OptArr = [{
  value: 'MarriedChildren',
  label: '已婚有子女'
}, {
  value: 'MarriedNoChildren',
  label: '已婚无子女'
}, {
  value: 'SingleChildren',
  label: '单身有子女'
}, {
  value: 'SingleNoChildren',
  label: '单身无子女'
}]

export const libilityListStatus:OptArr = [{
  value: 'Conservative',
  label: '保守型'
}, {
  value: 'ConservativeModerately',
  label: '适度保守型'
}, {
  value: 'Steady',
  label: '稳健型'
}, {
  value: 'PositiveModerately',
  label: '适度积极型'
}, {
  value: 'Positive',
  label: '积极型'
}]
